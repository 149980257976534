<template>
    <b-modal :visible="show" size="md" centered hide-header hide-footer hide-header-close>
        <div class="p-2">
            <div class="text-center">
                <app-heading-1>Upgrade to Service Provider</app-heading-1>
            </div>
            <div class="mt-2 text-center">
                By upgrading to the service provider you will be able to create projects, add collaboraters and create invoices etc.
            </div>
            <div class="mt-2 d-flex justify-content-center">
                <app-simple-button variant="primary" class="mr-1" :loading="loading" @click="upgradeT0ServiceProvider()">Upgrade</app-simple-button>
                <app-simple-button variant="outline-primary"  @click="()=>show=false">Cancel</app-simple-button>    
            </div>
        </div>
    </b-modal>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import { BModal, VBModal } from 'bootstrap-vue'
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import {updrade_to_service_provider} from '@/apis/others/user'
export default{
    components: {
        AppHeading1,
        AppSimpleButton,
        BModal,
        VBModal,
    },
    props:{
        heading:[String],
        cancelText:[String],
        confirmText:[String],
    },
    data(){
        return{
            show:false,
            loading:false
        }
    },
    methods:{
        openModal(){
            this.show = true;
        },
        hideModal(){
            this.show = false;
        },
        upgradeT0ServiceProvider(){
            this.loading=true;
            updrade_to_service_provider().then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                });
                this.loading=false;
                this.$emit('onUpgraded')
                this.hideModal();
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.loading=false;
            })
        }
    }
}
</script>
<style scoped>
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
}
</style>