<template>
    <div>
        <b-modal ref="createModal" size="md" centered hide-header hide-footer hide-header-close no-close-on-backdrop>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Create Signatures</app-heading-1>
                    <app-normal-text>Upload image of your signatures</app-normal-text>
                </div>
                <div class="my-2">
                    <vue-dropzone v-if="!file" ref="myFileDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" @vdropzone-file-added="checkFileSize">
                        <div class="dropzone-custom-content">
                            <img class="mb-2" :src="require('@/assets/images/app-icons/upload_icon.png')" alt="">
                            <h3 class="dropzone-custom-title">Drop files here or click to upload</h3>
                            <div>Only PNG and JPEG files are allowed (max size upto 7 MB)</div>
                          </div>
                    </vue-dropzone>
                    <div class="saved-signature mx-auto" v-else>
                        <img ref="filePreview" :src="url" class="w-100 h-100"  alt="">
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :disabled="!file" :loading="loading"  @click="moveNext">Save</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import { BModal, VBModal } from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import {create_signature} from "@/apis/others/user"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    components: {
        AppHeading1,
        AppSimpleButton,
        BModal,
        VBModal,
        AppNormalText,
        vueDropzone: vue2Dropzone,
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            dropzoneOptions: {
                autoProcessQueue: false,
                url: 'https://localhost',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                maxFiles:1,
                acceptedFiles:'image/jpeg,image/png,image/jpg'
            },
            file:'',
            loading:false,
            show:false,
            errorMessage: '',
            url:'',
        }
    },
    methods:{
        showModal(){
            this.$refs.createModal.show()
            this.file=''
            this.loading=false
        },
        hideModal(){
            this.$refs.createModal.hide()
        },
        checkFileSize(e){
            if(e.size / Math.pow(1024, 2)<=7){
                this.addFile(e)
            }else{
                this.$refs.myFileDropzone.removeFile(e);
                this.$bvToast.toast('File size should be less than 7 MB', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }
        },
        addFile(e){
            if(['image/jpeg','image/png','image/jpg'].includes(e.type)){
                this.file=e;
                this.url=URL.createObjectURL(this.file)
            }else{
                this.$refs.myFileDropzone.removeFile(e);
                this.$bvToast.toast('Upload valid image file', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }

        },
        moveNext(){
            this.loading=true;
            let data= new FormData();
            data.append('file',this.file);
            create_signature(data).then(resp=>{
                this.loading=false;
                this.$refs.createModal.hide()
                this.$emit('onSaved')
            });
        },
        
    },
}
</script>
<style scoped>
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .saved-signature{
    border-radius: 6px;
    background-color: #f8fafc;
    border: 1px dashed #DBDADE;
    width: 320px;
    height: 170px;
}
</style>