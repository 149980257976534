<template>
    <div>
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            class="container-fluid"
        >
            <div class="row">
                <div class="col-12 col-md-6 col-xl-4 mb-2" v-for="(s,index) in subscriptionPackages" :key="index">
                    <subscription-card :name="s.name" :price="s.price" :docForSign="s.documentsForSignature" :invoices="s.invoiceSendReceive" :manageReleasedPayments="s.managedReleasePayments" :collaborators="s.partnerCollaboration" :quickBooksIntegration="s.quickbooksIntegration" :stripeProductId="s.stripeProductId" :subscribed="s.name==currentPlan" class="h-100" @cancel="$refs.confirmModal.openModal()"/>
                </div>
            </div>
        </b-overlay>
        <app-confirm-modal ref="confirmModal" heading="Are you sure, you want to cancel subscription?" cancelText="Not Now" confirmText="Cancel Now" @onConfirm="cancelSubscription()">By canceling the subscription, you won't be able to avail perks.</app-confirm-modal>
    </div>
</template>
<script>
import SubscriptionCard from '@/components/functional-components/other/subscription/SubscriptionCard';
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue';
import {BOverlay} from 'bootstrap-vue'
import {get_subscription_plans, subscription_status,cancel_subscription} from '@/apis/others/subscriptions'

export default{
    components: {
        SubscriptionCard,
        AppConfirmModal,
        BOverlay
    },
    data(){
        return{
            subscriptionPackages:[],
            currentPlan:'',
            loading:false,
        }
    },
    mounted(){
        this.getSubscriptionPlans()
        this.getSubscriptionStatus()
    },
    methods:{
        getSubscriptionPlans(){
            this.loading=true;
            get_subscription_plans().then(resp=>{
                this.subscriptionPackages=resp
                this.getSubscriptionStatus()
                }
            )
        },
        getSubscriptionStatus(){
            subscription_status().then(resp=>{
                this.currentPlan=resp.data.subscriptionTier
                this.loading=false;
                // console.log("Subscription status:", resp)
            })
        },
        cancelSubscription(){
            cancel_subscription().then(resp=>{ 
                this.$bvToast.toast(resp.message, {
                    title: 'Saved to backup',
                    variant: 'success',
                    solid: true,
                })
                this. getSubscriptionStatus()
                this.$refs.confirmModal.hideModal()
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    })
            })
        }
    }
}
</script>
<style>
</style>