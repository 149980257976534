<template>
    <div class="bg-white rounded p-24 border-0 my-2">
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            >
            <div class="text-bold">Connect to Quickbooks</div>
            <div class="row">
                <div class="col-md-6 col-12  mb-1">
                    <div class="d-flex justify-content-between mt-1 align-items-center" v-if="accountDetails.companyName">
                        <app-heading-3 class=""><b>Connected Account</b><br>{{accountDetails.companyName}} ({{ accountDetails.email }})</app-heading-3>
                        <feather-icon icon="TrashIcon" class="text-danger cursor-pointer" size="18" @click="deleteAccount"></feather-icon>

                    </div>
                        <b-button v-else variant="success" class="mt-1" @click="connectQuickbooks">Connect Quickbooks</b-button>
                </div>
            </div>
        </b-overlay>
    </div>   
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import {BButton,BOverlay} from 'bootstrap-vue'
import {get_quicbooks_details,set_quickbooks_details, get_quickbooks_account_details, remove_account} from '@/apis/others/quickbooks'
export default {
    components:{
        BButton,
        BOverlay,
        AppHeading3
    },
    data(){
        return {
            loading:false,
            accountDetails:{
                companyName:'',
                email:''
            }
        }
    },
    mounted(){
        this.getQucikbooksAccount()
        // this.setQuickbooks({code:'AB11716468475SJFKcaoWcNYWrFUjgmbuyIWWSUhymOH4kGZaX',state:'Init', realmId:'9341452254387349'})
        if(this.$route.query.code){
            this.setQuickbooks({code:this.$route.query.code,state:this.$route.query.state, realmId:this.$route.query.realmId})
        }
    },
    methods:{
        connectQuickbooks(){
            get_quicbooks_details().then(resp=>{
                window.open(resp.data,'_blank')
            })
        },
        setQuickbooks(data){
            this.loading=true
            set_quickbooks_details(data).then(resp=>{
                this.getQucikbooksAccount()
                this.loading=false
            }).catch(err=>{
                this.loading=false
            })
        },
        getQucikbooksAccount(){
            this.accountDetails = {
                companyName:'',
                email:''
            }
            this.loading=true
            get_quickbooks_account_details().then(resp=>{
                this.accountDetails = resp.data;
                this.loading=false
            }).catch(e=>{
                this.loading=false
            })
        },
        deleteAccount(){
            remove_account().then(resp=>{
                console.log('account removed')
                this.getQucikbooksAccount()
            })
        }
    }

}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}

.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #4B465C;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #4B465C;
}
.text-small{
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #4B465C;
}
.mb-6{
    margin-bottom: 6px;
}
.input-field{
    border:1px solid #DBDADE;
    border-radius: 6px;
    padding: 9px 14px;
}
.del-btn:hover{
    opacity: .8;
}
.update-btn:hover{
    opacity: .8
}
.m-right-4{
    margin-right: 4px;
}
</style>