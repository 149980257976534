<template>
    <div class="bg-white p-24 mt-2 " >
        <div class="d-flex justify-content-between ">
            <div>
                <b-badge variant="light-primary">{{name}}</b-badge>
                <!-- <span v-else class="professional-badge">{{name}}</span> -->
            </div>
            <div class="drop-position">
                <b-dropdown
                    v-if="subscribed && name!='Free'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="Block Level Dropdown Menu"
                    right
                    variant="none"
                    size="sm"
                    no-caret
                    class="p-0"
                    >
                    <template #button-content class="p-0">
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                        />
                    </template>
                    <b-dropdown-item @click="$emit('cancel')">Cancel Subscription</b-dropdown-item>
                </b-dropdown>  
            </div>
        </div>
        <!-- <div class="price-text mt-1" v-if="name=='Professional'">Contact Sales</div> -->
        <div class="mt-1"><span class="sup text-primary">$</span><span class="price-text text-primary">{{price}}</span><span class="text-simple">/month</span></div>
        <div class="border-top mt-1 py-1">
            <div class="text-simple text-primary" >
                <feather-icon icon="CheckIcon" size="16" class="text-primary"></feather-icon> {{ docForSign }} documents for signature
            </div>
            <div class="text-simple text-primary">
                <feather-icon icon="CheckIcon" size="16" class="text-primary"></feather-icon> {{ manageReleasedPayments}} managed release payments
            </div>
            <div class="text-simple text-primary">
                <feather-icon icon="CheckIcon" size="16" class="text-primary"></feather-icon> {{ invoices }} invoices to be paid or send
            </div>
            <div class="text-simple text-primary">
                <feather-icon icon="CheckIcon" size="16" class="text-primary"></feather-icon> {{ collaborators }} partner collaboration
            </div>
            <div class="text-simple text-primary" v-if="quickBooksIntegration">
                <feather-icon icon="CheckIcon" size="16" class="text-primary"></feather-icon> Quickbooks integration
            </div>
        </div>
        <div >
            <app-simple-button :class="[!quickBooksIntegration?'mt-2':'']"  variant="primary" @click="()=>$router.push({path:`/subscription/upgrade-plan/${name}`, query:{plan_id:stripeProductId, price:price}})" :disabled="(subscribed || name=='Free')">{{subscribed?'Subscribed':`Go ${name=='Popular'?'Free':name}`}}</app-simple-button>
        </div>
    </div>
</template>
<script>
import {BBadge, BDropdown, BDropdownItem} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
export default{
    directives: {
        Ripple,
    },
    components:{
        BBadge,
        AppSimpleButton,
        BDropdown, 
        BDropdownItem
    },
    props:{
        name:[String],
        price:[String,Number],
        docForSign:[String,Number],
        invoices:[String,Number],
        manageReleasedPayments:[String,Number],
        collaborators:[String,Number],
        quickBooksIntegration:[Boolean],
        stripeProductId:[String],
        subscribed:[Boolean],
    },
    data(){
        return{
            //
        }
    },
    methods: {
        
    },
}
</script>
<style scoped>

.p-24{
    padding: 24px;
    border-radius: 6px;
}

.text-simple{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #4B465C;
}
.professional-badge{
    padding:5px 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 12px -5px rgba(255,255,255,0.5);
    -webkit-box-shadow: 0px 0px 12px -5px rgba(255,255,255,0.5);
   -moz-box-shadow: 0px 0px 12px -5px rgba(255,255,255,0.5);
}
.sup{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-right: 4px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    position: relative;
    bottom: 15px;
}
.price-text{
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
}
.drop-position{
    position: absolute;
    right: 20px;
}
</style>